import { QueryClient, useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import {
  LibrarySidebarCount,
  PlayerSidebarCount,
  PresentationSidebarCount,
  UserSidebarCount,
  Location,
  Network,
  Plugin,
  SmartGroup,
  MediaGroup,
  PlayerGroup,
  GroupCategory,
  ReportRequest,
  Preference,
  CurrentUser,
  Firmware,
  Player,
  PlayerModelDefinition,
  DisplayConfiguration,
  Tag,
  TagGroup,
  Media,
  Presentation,
  User,
  CustomField,
  Customer,
} from 'neocast-api-js-client'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { IndexTableColumn } from 'components/Utils/TableIndexColumn'
import { computeIncludesHash } from './utils'
import { FieldArg } from 'spraypaint/lib-esm/scope'
import { FolderSupportingClasses } from './BackendAPI'
import { encodeURIComponentWithUndefined } from 'utils'
import { presentationDetailsPath } from 'Routes'
import { PresentationDetails } from 'components/Presentations/PresentationEdit/types'

export function useInvalidateTheUniverse() {
  const queryClient = useQueryClient()

  return {
    invalidateTheUniverse: () => {
      queryClient.invalidateQueries()
    },
  }
}

export function useInvalidateAllSidebarCounts() {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({ queryKey: ['presentationSidebarCounts'] })
    queryClient.invalidateQueries({ queryKey: ['librarySidebarCounts'] })
    queryClient.invalidateQueries({ queryKey: ['deviceSidebarCounts'] })
    queryClient.invalidateQueries({ queryKey: ['userSidebarCounts'] })
  }
}

export function useInvalidatePresentationSidebarCounts() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: ['presentationSidebarCounts'] })
}

export function usePresentationSidebarCounts() {
  const queryKey = ['presentationSidebarCounts']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => await PresentationSidebarCount.first(),
  })

  return { ...q, presentationSidebarCounts: q.data?.data, meta: q.data?.meta }
}

export function useInvalidateUserSidebarCounts() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: ['useSidebarCounts'] })
}

export function useUserSidebarCounts() {
  const queryKey = ['userSidebarCounts']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => await UserSidebarCount.first(),
  })

  return { ...q, userSidebarCounts: q.data?.data, meta: q.data?.meta }
}

export function useInvalidateDeviceSidebarCounts() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: ['deviceSidebarCounts'] })
}

export function useDeviceSidebarCounts() {
  const queryKey = ['deviceSidebarCounts']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => await PlayerSidebarCount.first(),
  })

  return { ...q, deviceSidebarCounts: q.data?.data, meta: q.data?.meta }
}

export function useInvalidateLibrarySidebarCounts() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: ['librarySidebarCounts'] })
}

export function useLibrarySidebarCounts() {
  const queryKey = ['librarySidebarCounts']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => await LibrarySidebarCount.first(),
  })

  return { ...q, librarySidebarCounts: q.data?.data, meta: q.data?.meta }
}

export function useLocation({ id }: { id: string | number | undefined }) {
  const queryKey = ['location', id]
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id) {
        return await Location.find(id)
      } else {
        return null
      }
    },
  })

  return { ...q, location: q.data?.data, meta: q.data?.meta }
}

export function useNetwork({ id }: { id: string | undefined }) {
  const queryKey = ['network', id]
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id) {
        return await Network.find(id)
      } else {
        return null
      }
    },
  })

  return { ...q, network: q.data?.data, meta: q.data?.meta }
}

export function useSmartGroup({ id }: { id: string | undefined }) {
  const queryKey = ['smartGroup', id]
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id) {
        return await SmartGroup.find(id)
      } else {
        return null
      }
    },
  })

  return { ...q, smartGroup: q.data?.data, meta: q.data?.meta }
}

export function useMediaGroup({ id }: { id: string | undefined }) {
  const queryKey = ['mediaGroup', id]
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id) {
        return await MediaGroup.find(id)
      } else {
        return null
      }
    },
  })

  return { ...q, mediaGroup: q.data?.data, meta: q.data?.meta }
}

export function usePlayerGroup({ id }: { id: string | undefined }) {
  const queryKey = ['playerGroup', id]
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id) {
        return await PlayerGroup.find(id)
      } else {
        return null
      }
    },
  })

  return { ...q, playerGroup: q.data?.data, meta: q.data?.meta }
}

export function useFolder({ id }: { id: string | undefined }) {
  const queryKey = ['folder', id]
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id) {
        return await GroupCategory.find(id)
      } else {
        return null
      }
    },
  })

  return { ...q, folder: q.data?.data, meta: q.data?.meta }
}

export interface RecentlyViewedObject {
  id: string
  model_object_id: string
  model_object_name: string
  model_object_type: string
  polymorphic_model_object_type: string
  target_class?: string
}

export const usePresentationDetails = ({ id }: { id: string | undefined }) => {
  const queryKey = ['presentationDetails', id]

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      const { data: details } = await axios.post(presentationDetailsPath(), { presentation_id: id })

      return details as PresentationDetails
    },
  })

  return { ...q, presentationDetails: q.data }
}

export function useRecentlyViewedObjects() {
  const queryKey = ['recentlyUsedObjects']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      const { data: recentlyViewedObjects } = await axios.get('/api/v1/recently_viewed_objects')

      return recentlyViewedObjects as RecentlyViewedObject[]
    },
  })

  return { ...q, recentlyViewedObjects: q.data }
}

export const emptyDashboardData = {
  locations: [],
  presentations_count: 0,
  launched_presentations_count: 0,
  report_requests_count: 0,
  active_player_count: 0,
  expired_media_count: 0,
  expired_presentations_count: 0,
  media_count: 0,
  offline_player_count: 0,
  online_player_count: 0,
  player_count: 0,
  stopped_presentations_count: 0,
  used_media_count: 0,
  user_count: 0,
}

export interface DashboardData {
  locations: {
    id: number
    name: string
    lat: number
    lng: number
  }[]
  presentations_count: number
  launched_presentations_count: number
  stopped_presentations_count: number
  expired_presentations_count: number
  media_count: number
  used_media_count: number
  expired_media_count: number
  player_count: number
  online_player_count: number
  offline_player_count: number
  active_player_count: number
  user_count: number
}

export function useDashboardData() {
  const queryKey = ['dashboardData']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      const { data } = await axios.get<DashboardData>('/api/v1/dashboards')

      return data
    },
  })

  return { ...q, dashboardData: q.data }
}

export type ObjectHistory = {
  id: number
  name: string
  updated_at: string
  created_at: string
  user_name: string | undefined
  type: string
}

export function useObjectHistories(completedUploadCount: number) {
  const queryKey = ['objectHistories', completedUploadCount]
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      const { data } = await axios.get<ObjectHistory[]>('/api/v1/object_histories')

      return data
    },
  })

  return { ...q, objectHistories: q.data }
}

export function useReportRequests() {
  const queryKey = ['reportRequests']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return ReportRequest.includes(['target']).all()
    },
  })

  return { ...q, reportRequests: q.data?.data, meta: q.data?.meta }
}

export function usePlayerAndCustomer({ id }: { id: string | null }) {
  const queryKey = ['playerAndCustomer', id]

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id === null) {
        return null
      }

      return Player.includes(['customer']).find(id)
    },
  })

  return { ...q, player: q.data?.data, meta: q.data?.meta }
}

export function usePlayerGroupAndCustomer({ id }: { id: string | null }) {
  const queryKey = ['playerGroupAndCustomer', id]

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id === null) {
        return null
      }

      return PlayerGroup.includes(['customer']).find(id)
    },
  })

  return { ...q, playerGroup: q.data?.data, meta: q.data?.meta }
}

export function useLocationAndCustomer({ id }: { id: string | null }) {
  const queryKey = ['locationAndCustomer', id]

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id === null) {
        return null
      }

      return Location.includes(['customer']).find(id)
    },
  })

  return { ...q, location: q.data?.data, meta: q.data?.meta }
}

export function useMediaSmartGroupAndCustomer({ id }: { id: string | null }) {
  const queryKey = ['mediaSmartGroupAndCustomer', id]

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id === null) {
        return null
      }

      return SmartGroup.where({ targetClass: 'Media' }).includes(['customer']).find(id)
    },
  })

  return { ...q, mediaSmartGroup: q.data?.data, meta: q.data?.meta }
}

export function usePlayerSmartGroupAndCustomer({ id }: { id: string | null }) {
  const queryKey = ['playerSmartGroupAndCustomer', id]

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id === null) {
        return null
      }

      return SmartGroup.where({ targetClass: 'Player' }).includes(['customer']).find(id)
    },
  })

  return { ...q, playerSmartGroup: q.data?.data, meta: q.data?.meta }
}

export function useMediaGroupAndCustomer({ id }: { id: string | null }) {
  const queryKey = ['mediaGroupAndCustomer', id]

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id === null) {
        return null
      }

      return MediaGroup.includes(['customer']).find(id)
    },
  })

  return { ...q, mediaGroup: q.data?.data, meta: q.data?.meta }
}

export function useMediaAndCustomer({ id }: { id: string | null }) {
  const queryKey = ['mediaAndCustomer', id]

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id === null) {
        return null
      }

      return Media.includes(['customer']).find(id)
    },
  })

  return { ...q, media: q.data?.data, meta: q.data?.meta }
}

export function useNetworkAndCustomer({ id }: { id: string | null }) {
  const queryKey = ['networkAndCustomer', id]

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      if (id === null) {
        return null
      }

      return Network.includes(['customer']).find(id)
    },
  })

  return { ...q, network: q.data?.data, meta: q.data?.meta }
}

export function usePlayerModelDefinitions() {
  const queryKey = ['playerModelDefinitions']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return PlayerModelDefinition.order('model_identifier').all()
    },
  })

  return { ...q, playerModelDefinitions: q.data?.data, meta: q.data?.meta }
}

export function useDisplayConfigurationById({ id }: { id: string }) {
  const queryKey = ['displayConfiguration', id]
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return DisplayConfiguration.find(id)
    },
  })

  return { ...q, displayConfiguration: q.data?.data, meta: q.data?.meta }
}

export function useTagsWithTaggings({
  taggableObjectId,
  taggableType,
}: {
  taggableObjectId: string
  taggableType: string
}) {
  const queryKey = ['tagsWithTaggings', taggableObjectId, taggableType]
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return Tag.includes(['taggings'])
        .where({ taggings: { taggable_id: taggableObjectId, taggable_type: taggableType } })
        .all()
    },
  })

  return { ...q, tags: q.data?.data, meta: q.data?.meta }
}

export function useTagGroups() {
  const queryKey = ['tagGroups']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return TagGroup.order('name').all()
    },
  })

  return { ...q, tagGroups: q.data?.data, meta: q.data?.meta }
}

export function useCurrentCustomer() {
  const queryKey = ['currentCustomer']

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return Customer.includes(['customFields']).find(0)
    },
  })

  return { ...q, customer: q.data?.data, meta: q.data?.meta }
}

export function useCurrentUser() {
  const queryKey = ['currentUser']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return CurrentUser.first()
    },
  })

  return { ...q, currentUser: q.data?.data, meta: q.data?.meta }
}

export function useInvalidateUserPreference() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: ['userPreference'] })
}

export function useUserPreference() {
  const queryKey = ['userPreference']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return Preference.first()
    },
  })

  return { ...q, userPreference: q.data?.data, meta: q.data?.meta }
}

export function useGroupCategories({ className }: { className: string }) {
  const queryKey = ['groupCategories', className]
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return GroupCategory.selectExtra(['members']).order({ name: 'asc' }).where({ target_class: className }).all()
    },
  })

  return { ...q, groupCategories: q.data?.data, meta: q.data?.meta }
}

export function useFirmware({ playerIds }: { playerIds: string[] }) {
  const queryKey = ['firmwareForPlayerIDs', playerIds]
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return Firmware.where({ for_player_ids: playerIds }).all()
    },
  })

  return { ...q, firmware: q.data?.data, meta: q.data?.meta }
}

export function usePlugins() {
  const queryKey = ['plugins']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return Plugin.all()
    },
  })

  return { ...q, plugins: q.data?.data, meta: q.data?.meta }
}

export function usePlayersInLocation({ locationId }: { locationId: string | number }) {
  const queryKey = ['playersInLocation']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return Player.where({ location_id: locationId }).all()
    },
  })

  return { ...q, players: q.data?.data, meta: q.data?.meta }
}

export function useAllNetworks() {
  const queryKey = ['networks']
  // console.log({ queryKey })

  const q = useQuery({
    queryKey,
    queryFn: async () => {
      return Network.order('name').all()
    },
  })

  return { ...q, networks: q.data?.data, meta: q.data?.meta }
}

export function usePlayers({
  network_id,
  location_id,
  smart_group_id,
  player_group_id,
  filter,
  sortKey,
  sortDirection,
  columns,
  userSelectedColumnKeys,
  includes,
}: {
  network_id?: string
  location_id?: string
  smart_group_id?: string
  player_group_id?: string
  filter?: string
  sortKey: string
  sortDirection: string
  columns: IndexTableColumn[]
  userSelectedColumnKeys: string[]
  includes: string[]
}) {
  const sortDirectionString = sortDirection.toLowerCase() === 'desc' ? 'desc' : 'asc'

  let query = Player.stats({ total: 'count' })
    .includes([...includes, ...computeIncludesHash({ columns, userSelectedColumnKeys })])
    .where({ search_text: { match: encodeURIComponentWithUndefined(filter) } })
    .order({ [sortKey]: sortDirectionString })
    .per(20)

  if (network_id) {
    query = query.where({ network_id })
  }

  if (location_id) {
    query = query.where({ location_id })
  }

  if (smart_group_id) {
    query = query.where({ smart_group_id })
  }

  if (player_group_id) {
    query = query.where({ player_group_id })
  }

  const queryKey = [
    'players',
    filter,
    network_id,
    location_id,
    smart_group_id,
    player_group_id,
    sortKey,
    sortDirection,
    includes,
  ]

  return useInfiniteQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async ({ pageParam = 0 }) => {
      return query.page(pageParam).all()
    },
  })
}

export const usePresentations = ({
  smart_group_id,
  filter,
  sortKey,
  sortDirection,
  selectExtra,
  includes,
}: {
  smart_group_id?: string
  filter: string
  sortKey: string
  sortDirection: string
  selectExtra: string[]
  includes: string[]
}) => {
  const queryKey = ['presentations', filter, smart_group_id, sortKey, sortDirection, includes]
  // console.log({ queryKey })

  const sortDirectionString = sortDirection.toLowerCase() === 'desc' ? 'desc' : 'asc'

  let query = Presentation.stats({ total: 'count' })
    .where({ search_text: { match: encodeURIComponentWithUndefined(filter) } })
    .selectExtra(selectExtra)
    .order({ [sortKey]: sortDirectionString })
    .per(20)

  if (smart_group_id) {
    query = query.where({ smart_group_id })
  }

  if (includes.length > 0) {
    query = query.includes(includes)
  }

  return useInfiniteQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async ({ pageParam = 0 }) => {
      return query.page(pageParam).all()
    },
  })
}

export function useNetworks({
  filter,
  select,
  selectExtra,
  includes,
  sortKey,
  sortDirection,
}: {
  filter?: string
  sortKey: string
  sortDirection: string
  includes: string[]
  select?: FieldArg
  selectExtra: string[]
}) {
  const sortDirectionString = sortDirection.toLowerCase() === 'desc' ? 'desc' : 'asc'

  let query = Network.stats({ total: 'count' })
    .order({ [sortKey]: sortDirectionString })
    .per(20)

  if (selectExtra) {
    query = query.selectExtra(selectExtra)
  }

  if (select) {
    query = query.select(select)
  }

  if (filter) {
    query = query.where({ network_or_location_name: { match: encodeURIComponentWithUndefined(filter) } })
  }

  if (includes.length > 0) {
    query = query.includes(includes)
  }

  const queryKey = ['networks', filter, sortKey, sortDirection, select, selectExtra, includes]

  return useInfiniteQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async ({ pageParam = 0 }) => {
      return query.page(pageParam).all()
    },
  })
}

export function useAllLocations({
  filter,
  network_id,
  select,
  selectExtra,
  includes,
  sortKey,
  sortDirection,
}: {
  filter: string
  network_id?: string
  select: FieldArg
  includes: string[]
  selectExtra: string[]
  sortKey: string
  sortDirection: string
}) {
  const sortDirectionString = sortDirection.toLowerCase() === 'desc' ? 'desc' : 'asc'

  let query = Location.stats({ total: 'count' })
    .order({ [sortKey]: sortDirectionString })
    .per(20)

  if (network_id) {
    query = query.where({ network_id })
  }

  if (selectExtra) {
    query = query.selectExtra(selectExtra)
  }

  if (select) {
    query = query.select(select)
  }

  if (filter) {
    query = query.where({ search_text: { match: encodeURIComponentWithUndefined(filter) } })
  }

  if (includes.length > 0) {
    query = query.includes(includes)
  }

  const queryKey = ['locations', filter, network_id, sortKey, sortDirection, select, selectExtra, includes]
  // console.log({ queryKey })

  return useInfiniteQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async ({ pageParam = 0 }) => {
      return query.page(pageParam).all()
    },
  })
}

export function useAllSmartGroups({
  folder_id,
  filter,
  targetClass,
  select,
  selectExtra,
  sortKey,
  sortDirection,
  includes,
}: {
  folder_id?: string
  filter: string
  targetClass?: string
  select: FieldArg
  selectExtra: string[]
  sortKey: string
  sortDirection: string
  includes: string[]
}) {
  const sortDirectionString = sortDirection.toLowerCase() === 'desc' ? 'desc' : 'asc'

  let query = SmartGroup.stats({ total: 'count' })
    .where({ targetClass })
    .order({ [sortKey]: sortDirectionString })
    .per(20)

  if (folder_id) {
    query = query.where({ group_category_id: folder_id })
  }

  if (selectExtra) {
    query = query.selectExtra(selectExtra)
  }

  if (select) {
    query = query.select(select)
  }

  if (filter) {
    query = query.where({ search_text: { match: encodeURIComponentWithUndefined(filter) } })
  }

  if (includes.length > 0) {
    query = query.includes(includes)
  }

  const queryKey = [
    'smartGroups',
    targetClass,
    filter,
    folder_id,
    sortKey,
    sortDirection,
    select,
    selectExtra,
    includes,
  ]
  // console.log({ queryKey })

  return useInfiniteQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async ({ pageParam = 0 }) => {
      return query.page(pageParam).all()
    },
  })
}

export function useAllPlayerGroups({
  folder_id,
  filter,
  select,
  selectExtra,
  sortKey,
  sortDirection,
  includes,
}: {
  folder_id?: string
  filter: string
  select: FieldArg
  selectExtra: string[]
  sortKey: string
  sortDirection: string
  includes: string[]
}) {
  const sortDirectionString = sortDirection.toLowerCase() === 'desc' ? 'desc' : 'asc'

  let query = PlayerGroup.stats({ total: 'count' })
    .order({ [sortKey]: sortDirectionString })
    .per(20)

  if (folder_id) {
    query = query.where({ group_category_id: folder_id })
  }

  if (selectExtra) {
    query = query.selectExtra(selectExtra)
  }

  if (select) {
    query = query.select(select)
  }

  if (filter) {
    query = query.where({ search_text: { match: encodeURIComponentWithUndefined(filter) } })
  }

  if (includes.length > 0) {
    query = query.includes(includes)
  }

  const queryKey = ['playerGroups', filter, folder_id, sortKey, sortDirection, select, selectExtra, includes]
  // console.log({ queryKey })

  return useInfiniteQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async ({ pageParam = 0 }) => {
      return query.page(pageParam).all()
    },
  })
}

export function useAllGroupCategories({
  filter,
  select,
  selectExtra,
  sortKey,
  sortDirection,
  targetClass,
}: {
  filter: string
  select: FieldArg
  selectExtra: string[]
  sortKey: string
  sortDirection: string
  targetClass: FolderSupportingClasses
}) {
  const sortDirectionString = sortDirection.toLowerCase() === 'desc' ? 'desc' : 'asc'

  let query = GroupCategory.stats({ total: 'count' })
    .where({ targetClass })
    .order({ [sortKey]: sortDirectionString })
    .per(20)

  if (selectExtra) {
    query = query.selectExtra(selectExtra)
  }

  if (select) {
    query = query.select(select)
  }

  if (filter) {
    query = query.where({ search_text: { match: encodeURIComponentWithUndefined(filter) } })
  }

  const queryKey = ['folders', filter, sortKey, sortDirection, select, selectExtra]
  // console.log({ queryKey })

  return useInfiniteQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async ({ pageParam = 0 }) => {
      return query.page(pageParam).all()
    },
  })
}

export const useAllMedia = ({
  smart_group_id,
  media_group_id,
  type,
  filter,
  sortKey,
  sortDirection,
  select,
  selectExtra,
  includes,
}: {
  smart_group_id?: string
  media_group_id?: string
  type?: string
  filter: string
  sortKey: string
  sortDirection: string
  select: FieldArg
  selectExtra: string[]
  includes: string[]
}) => {
  const sortDirectionString = sortDirection.toLowerCase() === 'desc' ? 'desc' : 'asc'

  const types = (() => {
    switch (type) {
      case 'Audio':
        return ['Audio', 'AudioStream']
      case 'Feed':
        return ['Feed', 'MediaRssFeed']
      case null:
      case undefined:
        return null
      default:
        return [type]
    }
  })()

  let query = Media.stats({ total: 'count' })
    .where({ search_text: { match: encodeURIComponentWithUndefined(filter) } })
    .selectExtra(selectExtra)
    .order({ [sortKey]: sortDirectionString })
    .per(20)

  if (types) {
    query = query.where({ type: types })
  }

  if (select) {
    query = query.select(select)
  }

  if (smart_group_id) {
    query = query.where({ smart_group_id })
  }

  if (media_group_id) {
    query = query.where({ media_group_id })
  }

  if (includes.length > 0) {
    query = query.includes(includes)
  }

  const queryKey = [
    'allMedia',
    filter,
    types,
    smart_group_id,
    media_group_id,
    sortKey,
    sortDirection,
    select,
    selectExtra,
    includes,
  ]
  // console.log({ queryKey })

  return useInfiniteQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async ({ pageParam = 0 }) => {
      return query.page(pageParam).all()
    },
  })
}

export function useAllMediaGroups({
  folder_id,
  filter,
  select,
  selectExtra,
  sortKey,
  sortDirection,
  includes,
}: {
  folder_id?: string
  filter: string
  select: FieldArg
  selectExtra: string[]
  sortKey: string
  sortDirection: string
  includes: string[]
}) {
  const sortDirectionString = sortDirection.toLowerCase() === 'desc' ? 'desc' : 'asc'

  let query = MediaGroup.stats({ total: 'count' })
    .order({ [sortKey]: sortDirectionString })
    .per(20)

  if (folder_id) {
    query = query.where({ group_category_id: folder_id })
  }

  if (selectExtra) {
    query = query.selectExtra(selectExtra)
  }

  if (select) {
    query = query.select(select)
  }

  if (filter) {
    query = query.where({ search_text: { match: encodeURIComponentWithUndefined(filter) } })
  }

  if (includes.length > 0) {
    query = query.includes(includes)
  }

  const queryKey = ['mediaGroups', filter, folder_id, sortKey, sortDirection, select, selectExtra, includes]
  // console.log({ queryKey })

  return useInfiniteQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async ({ pageParam = 0 }) => {
      return query.page(pageParam).all()
    },
  })
}

export const useUsers = ({
  filter,
  sortKey,
  sortDirection,
  selectExtra,
  user_type,
  includes,
}: {
  filter: string
  sortKey: string
  sortDirection: string
  selectExtra?: FieldArg
  user_type?: string
  includes: string[]
}) => {
  const sortDirectionString = sortDirection.toLowerCase() === 'desc' ? 'desc' : 'asc'

  let query = User.stats({ total: 'count' })
    .where({ user_type, search_text: { match: encodeURIComponentWithUndefined(filter) } })
    .selectExtra(selectExtra || [])
    .order({ [sortKey]: sortDirectionString })
    .per(20)

  if (includes.length > 0) {
    query = query.includes(includes)
  }

  const queryKey = ['users', filter, user_type, sortKey, sortDirection, selectExtra, includes]
  // console.log({ queryKey })

  return useInfiniteQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async ({ pageParam = 0 }) => {
      return query.page(pageParam).all()
    },
  })
}

export function useOneUser({ id }: { id: string | undefined }) {
  const queryKey = ['user', id]

  const q = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async () => {
      if (!id) {
        return null
      }

      return User.selectExtra({
        users: ['can_update', 'can_delete', 'role_names', 'updated_by_name'],
        granular_permissions: ['permissable_type', 'permissable_name'],
      })
        .includes(['granular_permissions', { custom_field_values: ['custom_field'] }])
        .find(id)
    },
  })

  return { ...q, user: q.data?.data, meta: q.data?.meta }
}

export function useOnePlayerGroup({ id }: { id: string | undefined }) {
  const queryKey = ['player-group', id]

  const q = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async () => {
      if (!id) {
        return null
      }

      return PlayerGroup.selectExtra(['all_presentations', 'can_update', 'can_delete', 'updated_by_name'])
        .includes([
          'presentation_on_demand',
          { content_exclusions: ['media'], player_distribution: ['networks', 'players', 'locations', 'smart_groups'] },
        ])
        .find(id)
    },
  })

  return { ...q, playerGroup: q.data?.data, meta: q.data?.meta }
}

export function useOneFolder({ id }: { id: string | undefined }) {
  const queryKey = ['folder', id]

  const q = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async () => {
      if (!id) {
        return null
      }

      return GroupCategory.selectExtra(['smart_group_count', 'media_group_count']).find(id)
    },
  })

  return { ...q, folder: q.data?.data, meta: q.data?.meta }
}

export function usePresentation({ id, withJson = false }: { id: string | undefined; withJson?: boolean }) {
  const queryKey = ['presentation', id, withJson]

  const q = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async () => {
      let fields = [
        'can_update',
        'can_delete',
        'updated_by_name',
        'duration',
        'network_ids_and_names',
        'location_ids_and_names',
        'player_ids_and_names',
        'smart_group_ids_and_names',
        'player_group_ids_and_names',
        'player_distribution_details',
      ]

      if (withJson) {
        fields = [...fields, 'presentation_json']
      }

      if (!id) {
        return null
      }

      return Presentation.selectExtra(fields).includes(['display_configuration', 'plugins', 'time_range']).find(id)
    },
  })

  return { ...q, presentation: q.data?.data, meta: q.data?.meta }
}

export function useNetworkWithDetails({ id }: { id: string | undefined }) {
  const queryKey = ['network', id]

  const q = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async () => {
      if (!id) {
        return null
      }

      return Network.includes([
        { tags: ['tag_group'] },
        'bin_assignments',
        'presentation_on_demand',
        'on_demand_title_logo_media',
        {
          customized_time_ranges: ['time_range'],
          content_exclusions: ['media'],
          bin_assignments: ['media'],
          network_dialect_preferences: ['dialect'],
          custom_field_values: ['custom_field'],
        },
      ])
        .selectExtra({
          bin_assignments: ['bin_name', 'media_name', 'media_class'],
          networks: [
            'content_exclusions_via',
            'all_presentations',
            'can_delete',
            'can_update',
            'updated_by_name',
            'all_presentations',
            'operating_hours_timeblock_effective',
            'operating_hours_timeblock_inherited',
            'operating_hours_timeblock_inherited_from',
            'download_hours_timeblock_effective',
            'download_hours_timeblock_inherited',
            'download_hours_timeblock_inherited_from',
            'download_max_file_size_effective',
            'download_max_file_size_inherited',
            'location_count',
            'player_count',
            'player_groups',
          ],
        })
        .find(id)
    },
  })

  return { ...q, network: q.data?.data, meta: q.data?.meta }
}

export function useLocationWithDetails({ id }: { id: string | undefined }) {
  const queryKey = ['location', id]

  const q = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async () => {
      if (!id) {
        return null
      }

      return Location.includes([
        'network',
        { tags: ['tag_group'] },
        'bin_assignments',
        'presentation_on_demand',
        {
          network: ['presentation_on_demand'],
          customized_time_ranges: ['time_range'],
          content_exclusions: ['media'],
          bin_assignments: ['media'],
          location_dialect_preferences: ['dialect'],
          custom_field_values: ['custom_field'],
        },
      ])
        .selectExtra({
          bin_assignments: ['bin_name', 'media_name', 'media_class'],
          locations: [
            'content_exclusions_via',
            'can_update',
            'can_delete',
            'updated_by_name',
            'all_presentations',
            'operating_hours_timeblock_effective',
            'operating_hours_timeblock_inherited',
            'operating_hours_timeblock_inherited_from',
            'download_hours_timeblock_effective',
            'download_hours_timeblock_inherited',
            'download_hours_timeblock_inherited_from',
            'download_max_file_size_effective',
            'download_max_file_size_inherited',
            'playerCount',
            'player_groups',
          ],
        })
        .find(id)
    },
  })

  return { ...q, location: q.data?.data, meta: q.data?.meta }
}

export function useOneSmartGroup({ id }: { id: string | undefined }) {
  const queryKey = ['one-smart-group', id]

  const q = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async () => {
      if (!id) {
        return null
      }
      return SmartGroup.includes(['smart_group_conditions'])
        .selectExtra(['allPresentations', 'can_update', 'can_delete', 'updated_by_name'])
        .find(id)
    },
  })

  return { ...q, smartGroup: q.data?.data, meta: q.data?.meta }
}

export function useOneMediaGroup({ id }: { id: string | undefined }) {
  const queryKey = ['one-media-group', id]

  const q = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async () => {
      if (!id) {
        return null
      }

      return MediaGroup.includes([{ media_group_items: 'media' }])
        .selectExtra({ media_groups: ['allPresentations', 'can_update', 'can_delete', 'updated_by_name'] })
        .find(id)
    },
  })

  return { ...q, mediaGroup: q.data?.data, meta: q.data?.meta }
}

export function useSpecificMedia({ id }: { id: string | undefined }) {
  const queryKey = ['specific-media', id]

  const q = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async () => {
      if (!id) {
        return null
      }

      return Media.includes([
        'spot_swap_items',
        'feed_background_image',
        'linked_media',
        'feed_items',
        'feed_icon',
        'media_groups',
        'smart_groups',
        { tags: ['tag_group'] },
        { custom_field_values: 'custom_field', subtitles: 'dialect' },
      ])
        .selectExtra({
          media: [
            'excluders',
            'can_update',
            'can_delete',
            'updated_by_name',
            'all_presentations',
            'linked_media_name',
            'feed_icon',
          ],
          spot_swap_items: ['download_url', 'distributable_description'],
        })
        .find(id)
    },
  })

  return { ...q, media: q.data?.data, meta: q.data?.meta }
}

export function useSpecificPlayer({ id, refetchInterval }: { id: string | undefined; refetchInterval?: number }) {
  const queryKey = ['specific-player', id]

  const q = useQuery({
    queryKey,
    keepPreviousData: true,
    refetchInterval,
    queryFn: async () => {
      if (!id) {
        return null
      }

      return Player.includes([
        { tags: ['tag_group'] },
        'bin_assignments',
        'location',
        'network',
        { location: 'presentation_on_demand' },
        { network: 'presentation_on_demand' },
        'presentation_on_demand',
        'display_configuration',
        {
          customized_time_ranges: ['time_range'],
          bin_assignments: ['media'],
          player_dialect_preferences: ['dialect'],
          custom_field_values: ['custom_field'],
          video_outputs: ['video_output_resolutions'],
        },
      ])
        .selectExtra({
          bin_assignments: ['bin_name', 'media_name', 'media_class'],
          players: [
            'content_exclusions_via',
            'all_presentations',
            'operating_hours_timeblock_effective',
            'operating_hours_timeblock_inherited',
            'operating_hours_timeblock_inherited_from',
            'download_hours_timeblock_effective',
            'download_hours_timeblock_inherited',
            'download_hours_timeblock_inherited_from',
            'download_max_file_size_effective',
            'download_max_file_size_inherited',
            'display_source_name',
            'player_group_ids',
            'player_group_ids_with_on_demand',
            'display_configuration_object',
            'media_partition_bytes_total',
            'screenshot_url',
            'screenshot_timestamp',
            'tunnel_pending',
            'tunnel_port',
            'location_name',
            'network_name',
            'can_update',
            'can_delete',
            'player_groups',
            'smart_groups',
          ],
        })
        .find(id)
    },
  })

  return { ...q, player: q.data?.data, meta: q.data?.meta }
}

export function useCustomFields(model: string | undefined) {
  const queryKey = ['custom-fields', model || 'undefined']

  const q = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async () => {
      if (model === undefined) {
        return { data: [], meta: {} }
      }

      return CustomField.where({ model }).all()
    },
  })

  return { ...q, customFields: q.data?.data, meta: q.data?.meta }
}

export function useSpecificPlayerGroups({ ids }: { ids: string[] | undefined }) {
  const queryKey = ['specific-player-groups', ids]

  const q = useQuery({
    queryKey,
    keepPreviousData: true,
    queryFn: async () => {
      if (!ids) {
        return null
      }

      return (
        PlayerGroup.selectExtra(['updated_by_name'])
          .includes(['presentation_on_demand'])
          // Hack to ensure that we at least have some filter here
          .where({ id: [0, ...ids] })
          .all()
      )
    },
  })

  return { ...q, playerGroups: q.data?.data, meta: q.data?.meta }
}

export function defaultCache(client: QueryClient) {
  client.setQueryData(['useDashboardData'], { data: emptyDashboardData })
  client.setQueryData(['useRecentlyViewedObjects'], { data: [] })
  client.setQueryData(['smartGroup', undefined], { data: {} })
  client.setQueryData(['mediaGroup', undefined], { data: {} })
  client.setQueryData(['folder', undefined], { data: {} })
  client.setQueryData(['librarySidebarCounts'], { data: {} })
  client.setQueryData(['presentationSidebarCounts'], { data: {} })
  client.setQueryData(['userSidebarCounts'], { data: {} })
  client.setQueryData(['deviceSidebarCounts'], { data: {} })
  client.setQueryData(['location', undefined], { data: {} })
  client.setQueryData(['network', undefined], { data: {} })
  client.setQueryData(['folder', undefined], { data: {} })
  client.setQueryData(['smartGroup', undefined], { data: {} })
  client.setQueryData(['mediaGroup', undefined], { data: {} })
  client.setQueryData(['playerGroup', undefined], { data: {} })

  client.setQueryData(['currentUser'], { data: {} })
  client.setQueryData(['userPreference'], { data: {} })
  client.setQueryData(['presentations'], { data: {} })
  client.setQueryData(['reportRequests'], { data: {} })
  client.setQueryData(['tagGroups'], { data: {} })
  client.setQueryData(['presentations'], { data: {} })
  client.setQueryData(['players'], { data: {} })
  client.setQueryData(['users'], { data: {} })
  client.setQueryData(['mediaGroups'], { data: {} })
  client.setQueryData(['allMedia'], { data: {} })
  client.setQueryData(['folders'], { data: {} })
  client.setQueryData(['playerGroups'], { data: {} })
  client.setQueryData(['smartGroups'], { data: {} })
  client.setQueryData(['locations'], { data: {} })
  client.setQueryData(['networks'], { data: {} })
  client.setQueryData(['playersInLocation'], { data: {} })
  client.setQueryData(['plugins'], { data: {} })
  client.setQueryData(['firmwareForPlayerIDs'], { data: {} })
  client.setQueryData(['groupCategories'], { data: {} })
  client.setQueryData(['tagsWithTaggings'], { data: {} })
  client.setQueryData(['playerModelDefinitions'], { data: {} })
  client.setQueryData(['displayConfiguration'], { data: {} })
  client.setQueryData(['playerAndCustomer'], { data: {} })
  client.setQueryData(['dashboardData'], {})
  client.setQueryData(['recentlyUsedObjects'], [])
  client.setQueryData(['objectHistories'], {})
  client.setQueryData(['reportRequests'], [])
  client.setQueryData(['custom-fields', undefined], { data: [] })
  client.setQueryData(['custom-fields', 'Media'], { data: [] })
  client.setQueryData(['custom-fields', 'Presentation'], { data: [] })
  client.setQueryData(['custom-fields', 'Network'], { data: [] })
  client.setQueryData(['custom-fields', 'Location'], { data: [] })
  client.setQueryData(['custom-fields', 'Player'], { data: [] })
  client.setQueryData(['currentCustomer'], {})
}
