import { editSmartGroupPath } from './mediaRoutes'
import { RoutePathFunction } from './types'
import { paramsReplace } from './utils'

export const playerSmartGroupsIndexPath: RoutePathFunction = params => paramsReplace('/players/smart-groups', params)
export const playerSmartGroupsIndexPathWithRightBar: RoutePathFunction = params =>
  paramsReplace(`/players/smart-groups?rightbar=SmartGroup-:smart_group_id`, params)
export const playerStaticGroupsIndexPath: RoutePathFunction = params => paramsReplace('/players/player-groups', params)
export const playerStaticGroupsInFolderIndexPath: RoutePathFunction = params =>
  paramsReplace('/players/folders/:folder_id/player-groups', params)
export const playerSmartGroupsInFolderIndexPath: RoutePathFunction = params =>
  paramsReplace('/players/folders/:folder_id/smart-groups', params)

export const playerGroupIndexPath: RoutePathFunction = params =>
  paramsReplace('/players/player-groups/:player_group_id', params)
export const playerGroupIndexWithRightBarPath: RoutePathFunction = params =>
  paramsReplace('/players/player-groups?rightbar=:class-:id', params)
export const playerGroupIndexForPlayerGroupWithRightBarPath: RoutePathFunction = params =>
  paramsReplace('/players/player-groups/:player_group_id?rightbar=:class-:id', params)
export const playerSmartGroupIndexPath: RoutePathFunction = params =>
  paramsReplace('/players/smart-groups/:smart_group_id', params)
export const playerSmartGroupIndexWithRightBarPath: RoutePathFunction = params =>
  paramsReplace('/players/smart-groups?rightbar=:class-:id', params)
export const playerSmartGroupIndexForSmartGroupWithRightBarPath: RoutePathFunction = params =>
  paramsReplace('/players/smart-groups/:smart_group_id?rightbar=:class-:id', params)
export const locationIndexPath: RoutePathFunction = params =>
  paramsReplace('/players/network/:network_id/location/:location_id', params)
export const locationIndexWithRightBarPath: RoutePathFunction = params =>
  paramsReplace('/players/network/:network_id/location/:location_id?rightbar=Location-:location_id', params)
export const networkIndexPath: RoutePathFunction = params => paramsReplace('/players/network/:network_id', params)
export const networkIndexWithRightBarPath: RoutePathFunction = params =>
  paramsReplace('/players/network/:network_id?rightbar=Network-:network_id', params)
export const playerLogsPath = (params: { id?: string } = {}) => {
  return paramsReplace(`/players/:id/logs`, params)
}

export const logsMultiDayPath: RoutePathFunction = params => {
  return paramsReplace(`/logs-multi-day/:targetType/:id`, params)
}

export const logsMultiDayPathWithStartAndEndDate: RoutePathFunction = params => {
  return paramsReplace(`/logs-multi-day/:targetType/:id?startDate=:startDate&endDate=:endDate`, params)
}

export const editPlayerPath: RoutePathFunction = params => paramsReplace('/players/:id', params)
export const editPlayerGroupPath: RoutePathFunction = params => paramsReplace('/player-groups/:id', params)
export const importLocationsPath: RoutePathFunction = params => paramsReplace('/networks/:id/import_locations', params)
export const editNetworkPath: RoutePathFunction = params => paramsReplace('/networks/:id', params)
export const newNetworkPath: RoutePathFunction = params => paramsReplace('/networks/new', params)
export const newLocationPath: RoutePathFunction = params => paramsReplace('/locations/:id/:networkId', params)
export const editLocationPath: RoutePathFunction = params => paramsReplace('/locations/:id', params)
export const playerStaticGroupsIndexPathWithRightBar: RoutePathFunction = params =>
  paramsReplace('/players/player-groups?rightbar=PlayerGroup-:player_group_id', params)
export const playerFoldersIndexPath: RoutePathFunction = params => paramsReplace('/players/folders', params)
export const playerFoldersIndexPathWithRightBar: RoutePathFunction = params =>
  paramsReplace('/players/folders?rightbar=GroupCategory-:folder_id', params)
export const playerIndexPath: RoutePathFunction = params => {
  return paramsReplace('/players', params)
}
export const playerIndexNetworksPath: RoutePathFunction = params => {
  return paramsReplace('/networks', params)
}
export const playerIndexNetworksPathWithRightBar: RoutePathFunction = params => {
  return paramsReplace(`/networks?rightbar=Network-:network_id`, params)
}
export const playerIndexLocationsPath: RoutePathFunction = params => {
  return paramsReplace('/locations', params)
}
export const playerIndexLocationsPathWithRightBar: RoutePathFunction = params => {
  return paramsReplace('/locations?rightbar=Location-:location_id', params)
}
export const playerIndexLocationsForNetworkPath: RoutePathFunction = params => {
  return paramsReplace('/locations/network/:network_id', params)
}
export const playerIndexPlayersForNetworkPath: RoutePathFunction = params => {
  return paramsReplace('/players/network/:network_id', params)
}
export const playerIndexPlayersForLocationPath: RoutePathFunction = params => {
  return paramsReplace('/players/location/:location_id', params)
}

export const playerIndexWithRightBarPath: RoutePathFunction = params => {
  return paramsReplace('/players?rightbar=:class-:id', params)
}

export const playerIndexWithRightBarPathAndDisplayTab: RoutePathFunction = params => {
  return paramsReplace('/players?rightbar=:class-:id&tab=display', params)
}

export const allPlayerPaths = [
  editLocationPath(),
  editNetworkPath(),
  editNetworkPath(),
  editPlayerGroupPath(),
  editPlayerGroupPath(),
  editPlayerPath(),
  editPlayerPath(),
  editSmartGroupPath({ type: 'player' }),
  importLocationsPath(),
  importLocationsPath(),
  locationIndexPath(),
  locationIndexPath(),
  locationIndexWithRightBarPath(),
  networkIndexPath(),
  networkIndexPath(),
  networkIndexWithRightBarPath(),
  newLocationPath(),
  newLocationPath(),
  newNetworkPath(),
  playerFoldersIndexPath(),
  playerFoldersIndexPath(),
  playerGroupIndexPath(),
  playerGroupIndexPath(),
  playerGroupIndexForPlayerGroupWithRightBarPath(),
  playerGroupIndexWithRightBarPath(),
  playerIndexLocationsForNetworkPath(),
  playerIndexLocationsForNetworkPath(),
  playerIndexLocationsPath(),
  playerIndexLocationsPath(),
  playerIndexLocationsPathWithRightBar(),
  playerIndexLocationsPathWithRightBar(),
  playerIndexNetworksPath(),
  playerIndexNetworksPath(),
  playerIndexNetworksPathWithRightBar(),
  playerIndexNetworksPathWithRightBar(),
  playerIndexPath(),
  playerIndexPath(),
  playerIndexPlayersForLocationPath(),
  playerIndexPlayersForNetworkPath(),
  playerLogsPath(),
  playerSmartGroupIndexPath(),
  playerSmartGroupIndexPath(),
  playerSmartGroupIndexForSmartGroupWithRightBarPath(),
  playerSmartGroupsIndexPath(),
  playerSmartGroupsIndexPath(),
  playerSmartGroupsIndexPathWithRightBar(),
  playerSmartGroupsIndexPathWithRightBar(),
  playerSmartGroupsInFolderIndexPath(),
  playerStaticGroupsIndexPath(),
  playerStaticGroupsIndexPathWithRightBar(),
  playerStaticGroupsInFolderIndexPath(),
]
